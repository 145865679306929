import { initInlinePlugin } from "@chamaileon-sdk/plugin-interface";
import { generateUID } from "./chamaileonTokens";

const pluginDict = {
	thumbnail: {
		id: "thumbnail-wrapper",
		dashboardRef: "jsonPreview",
	},
	gallery: {
		id: "gallery-wrapper",
		dashboardRef: "megaGallery",
	},
	editor: {
		id: "editor-wrapper",
		dashboardRef: "realTimeEditor",
	},
	preview: {
		id: "email-preview-wrapper",
		dashboardRef: "megaPreview",
	},
	"variable-editor": {
		id: "variable-editor-wrapper",
		dashboardRef: "variablePreview",
	},
	import: {
		id: "html-import-wrapper",
		dashboardRef: "htmlImport",
	},
};

const occupiedUIDs = [];

export default function ({ sdkConfig }) {
	return async function createInlinePlugin(
		{
			plugin,
			data = {},
			settings = {},
			hooks = {},
		} = {},
		{
			container,
			dimensions: {
				height = "100%",
				width = "100%",
				scale = 1,
			} = {},
			beforeInit,
			timeout,
		} = {},
	) {
		if (!pluginDict[plugin]) {
			return new Error(`${plugin} is not a valid plugin name. Supported plugins: thumbnail, editor, preview, variable-editor, gallery, import`);
		}
		const pluginVersion = window.chamaileonEnvironmentConfig.plugins[pluginDict[plugin].dashboardRef];
		const src = window.chamaileonEnvironmentConfig.pluginUrlTemplates[pluginDict[plugin].dashboardRef]
			.replace("%version%", pluginVersion) + "?chamDomain=" + encodeURIComponent(sdkConfig.domain) + "&chamUid=" + sdkConfig.uid;

		if (!sdkConfig.accessToken.value || Date.now() - sdkConfig.accessToken.timeStamp >= 60 * 1000 * 60 * 23.9) {
			if (!sdkConfig.getAccessToken) {
				throw new Error("Couldn't generate new Token, getAccessToken hook not initialized");
			}
			const newAccessToken = await sdkConfig.getAccessToken();
			sdkConfig.accessToken.value = newAccessToken;
			sdkConfig.accessToken.timeStamp = Date.now();
		}
		let _container = container;
		if (typeof _container === "string") {
			_container = document.querySelector(_container);
		}

		if (!_container || _container.nodeType !== 1) {
			console.error("'container' must reference a valid DOM node.");
			return;
		}

		if (_container.innerHTML.trim()) {
			console.error("'container' must reference an empty DOM node.");
			return;
		}

		let wrapperUid;

		while (occupiedUIDs.includes(wrapperUid) || typeof wrapperUid === "undefined") {
			wrapperUid = generateUID(8);
		}

		occupiedUIDs.push(wrapperUid);

		const wrapper = document.createElement("div");
		wrapper.id = `${pluginDict[plugin].id}-${wrapperUid}`;

		// Deprecated, moved it onto the second object's parameters
		// but the thumbnail still can use this setting based config as well
		const { width: settingsWidth = null, height: settingsHeight = null, scale: settingsScale = null } = settings;

		const parsedScale = settingsScale !== null ? parseFloat(settingsScale) : parseFloat(scale);
		let parsedWidth = settingsWidth !== null ? settingsWidth : width;
		let parsedHeight = settingsHeight !== null ? settingsHeight : height;

		if (typeof parsedWidth === "number") { // TODO: better checks?
			parsedWidth = parseInt(parsedWidth);
			wrapper.style.width = `${parsedWidth * parsedScale}px`;
		} else {
			wrapper.style.width = parsedWidth;
		}

		if (typeof parsedHeight === "number") { // TODO: better checks?
			parsedHeight = parseInt(parsedHeight);
			wrapper.style.height = `${parsedHeight * parsedScale}px`;
		} else {
			wrapper.style.height = parsedHeight;
		}

		wrapper.style.overflow = "hidden";

		_container.appendChild(wrapper);

		// eslint-disable-next-line no-shadow
		function _beforeInit({ container, iframe }) {
			if (typeof beforeInit === "function") {
				beforeInit({ container, iframe });
			}

			iframe.style.height = `${parsedHeight}px`;
			iframe.style.width = `${parsedWidth}px`;

			iframe.style.transform = `scale(${parsedScale})`;
			iframe.style["transform-origin"] = "0 0";
			iframe.style.border = "0";
		}

		return await initInlinePlugin({
			data,
			settings: {
				...settings,
				sdkConfig,
			},
			hooks,
		}, {
			src,
			container: wrapper,
			beforeInit: _beforeInit,
			timeout,
		});
	};
}
