export default async function sendUsageStats({ accessToken, uid, domain }) {
	try {
		const usageRequest = await fetch(`${window.chamaileonEnvironmentConfig.backends.stats}/serverless-events`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: accessToken.value,
				"Authorization-Domain": domain,
				uid,
			},
			body: JSON.stringify({
				action: "open-sdk",
			}),
		});

		await usageRequest.json();

		if (!usageRequest.ok) {
			console.warn("Usage request error");
		}
	} catch (e) {
		console.warn("Usage request error");
	}
}
