export default async function ({ environmentConfigUrl, environmentName }) {
	// Return if environment config is already loaded
	if (window.chamaileonEnvironmentConfig) return;
	const cacheTime = 30; // measured in seconds
	const timestampRounded = Math.round(new Date().getTime() / (cacheTime * 1000), 3) * cacheTime;
	const configScriptUrl = environmentConfigUrl.replace("%ENVNAME%", environmentName) + "?time=" + timestampRounded;
	try {
		const request = await fetch(configScriptUrl);
		const result = await request.json();
		window.chamaileonEnvironmentConfig = result;
		return result;
	} catch (e) {
		console.error("Failed to get environment config");
		console.error(e);
		return {};
	}
}
