import { initFullscreenPlugin } from "@chamaileon-sdk/plugin-interface";

const pluginDict = {
	gallery: {
		id: "gallery-wrapper",
		dashboardRef: "megaGallery",
	},
	editor: {
		id: "editor-wrapper",
		dashboardRef: "realTimeEditor",
	},
	preview: {
		id: "email-preview-wrapper",
		dashboardRef: "megaPreview",
	},
	"variable-editor": {
		id: "variable-editor-wrapper",
		dashboardRef: "variablePreview",
	},
	import: {
		id: "html-import-wrapper",
		dashboardRef: "htmlImport",
	},
};

export default function ({ sdkConfig, chamaileonPluginsWrapper }) {
	return async function createPlugin(
		{
			plugin,
			data = {},
			settings = {},
			hooks = {},
		} = {},
		{
			beforeInit,
			timeout,
		} = {},
	) {
		if (!pluginDict[plugin]) {
			return new Error(`${plugin} is not a valid plugin name. Supported plugins: editor, preview, variable-editor, gallery, import`);
		}

		const pluginVersion = window.chamaileonEnvironmentConfig.plugins[pluginDict[plugin].dashboardRef];
		const src = window.chamaileonEnvironmentConfig.pluginUrlTemplates[pluginDict[plugin].dashboardRef]
			.replace("%version%", pluginVersion) + "?chamDomain=" + encodeURIComponent(sdkConfig.domain) + "&chamUid=" + sdkConfig.uid;

		if (!sdkConfig.accessToken.value || Date.now() - sdkConfig.accessToken.timeStamp >= 60 * 1000 * 60 * 23.9) {
			if (!sdkConfig.getAccessToken) {
				throw new Error("Couldn't generate new Token, getAccessToken hook not initialized");
			}
			const newAccessToken = await sdkConfig.getAccessToken();
			sdkConfig.accessToken.value = newAccessToken;
			sdkConfig.accessToken.timeStamp = Date.now();
		}
		if (sdkConfig?.urls?.splashScreen) {
			settings.splashScreenUrl = sdkConfig.urls.splashScreen;
		}

		return await initFullscreenPlugin(
			{
				data,
				settings: {
					...settings,
					sdkConfig,
				},
				hooks,
			},
			{
				id: pluginDict[plugin].id,
				src,
				parentElem: chamaileonPluginsWrapper,
				beforeInit,
				timeout,
			},
		);
	};
}
